<template>
  <div>
    <v-stepper v-model="step" class="stepper--outlined" non-linear>
      <v-stepper-header>
        <v-stepper-step step="1" :editable="step > 1">
          {{ $t('medicalTests.rx_modality') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="2" :editable="step > 2">
          {{ $t('medicalTests.rx_anatomical_region') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="3">
          {{ $t('medicalTests.rx_exploration') }}
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <div class="px-1">
      <swiper ref="structure-swiper" :options="swiperOptions">
        <swiper-slide>
          <div v-if="!isLoadingCollections" class="overflow-y-auto medical-test-list">
            <v-list>
              <v-list-item
                v-for="collection in activeCollections"
                :key="collection.id"
                @click="selectCollection(collection.id)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ collection.description }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div v-else class="d-flex flex-column align-center justify-center medical-test-list">
            <v-progress-circular indeterminate color="primary" :size="70" :width="3" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div v-if="!isLoadingSubGroups" class="overflow-y-auto medical-test-list">
            <v-list>
              <v-list-item v-for="subGroup in activeSubGroups" :key="subGroup.id" @click="selectSubGroup(subGroup.id)">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ subGroup.description }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item>
            </v-list>
          </div>
          <div v-else class="d-flex flex-column align-center justify-center medical-test-list">
            <v-progress-circular indeterminate color="primary" :size="70" :width="3" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div v-if="!isLoadingActs" class="overflow-y-auto medical-test-list">
            <v-checkbox
              v-for="(act, index) in activeActs"
              :key="index"
              v-model="selectedActs"
              hide-details
              :value="act"
              :value-comparator="(a, b) => a.id === b.id"
              :label="`${act.code ? act.code + ' - ' : ''}${act.description}`"
            />
          </div>
          <div v-else class="d-flex flex-column align-center justify-center medical-test-list">
            <v-progress-circular indeterminate color="primary" :size="70" :width="3" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { useNomenclator } from '@/use/MedicalTests/useNomenclator';
import { watch } from 'vue';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },

  setup: function(props, { emit }) {
    const {
      loadCollections,
      loadActs,
      setItemsPerPage,
      setSubGroup,
      isLoadingCollections,
      isLoadingSubGroups,
      isLoadingActs,
      selectedActs,
      activeActs,
      activeCollections,
      activeSubGroups,
      filter,
    } = useNomenclator(JSON.parse(window.localStorage.getItem('auth_ehealth')).user.professional_id, false);

    setItemsPerPage(10000);
    loadCollections('79cd58b7-949e-4658-8133-fdccfae27ac4');

    watch(
      () => selectedActs.value,
      currentValue => {
        return emit('input', currentValue);
      }
    );

    const selectCollection = collectionId => {
      filter.collectionId = collectionId;
    };

    const selectSubGroup = subgroupId => {
      filter.subgroupId = subgroupId;
    };

    return {
      loadActs,
      setItemsPerPage,
      setSubGroup,
      isLoadingCollections,
      isLoadingSubGroups,
      isLoadingActs,
      selectedActs,
      activeActs,
      activeCollections,
      activeSubGroups,
      selectCollection,
      selectSubGroup,
      filter,
    };
  },
  data: function() {
    return {
      selectedGroup: [],
      showActsDialog: false,
      step: 1,
      swiperOptions: {
        grabCursor: false,
        allowTouchMove: false,
      },
    };
  },
  computed: {
    medicaltTestsClass() {
      return {
        'd-flex': true,
        'flex-column': true,
        'flex-wrap': true,
      };
    },
  },
  watch: {
    selectedGroup(val) {
      if (val !== null) {
        this.setSubGroup(val);
        this.showActsDialog = true;
      }
    },
    showActsDialog() {
      if (!this.showActsDialog) {
        this.selectedGroup = null;
      }
    },
    step() {
      this.swipeToStep(this.step - 1);
    },
    'filter.collectionId'(val) {
      if (val) {
        this.step = 2;
      }
    },
    'filter.subgroupId'(val) {
      if (val) {
        this.step = 3;
      }
    },
  },
  methods: {
    swipeToStep(step) {
      const currentSwiper = this.$refs['structure-swiper'].$swiper;
      currentSwiper.slideTo(step, 250, false);
    },
    closeActsDialog() {
      this.showActsDialog = false;
    },
  },
};
</script>

<style>
.medical-test-list {
  height: 400px;
}

.stepper--outlined {
  box-shadow: none;
}
</style>
