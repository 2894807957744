<template>
  <div class="fill-height overflow-y-auto overflow-x-hidden px-1">
    <v-text-field
      v-model="filter.query"
      filled
      flat
      rounded
      dense
      clearable
      prepend-inner-icon="mdi-magnify"
      :placeholder="$t('medicalTests.search')"
      hide-details
      class="mt-3 mb-1"
    />
    <div v-if="!isLoadingActs" class="d-flex flex-row flex-wrap medical-test-list">
      <template v-if="searched">
        <template v-if="activeActs.length">
          <div v-for="(act, index) in activeActs" :key="index" class="medical-test">
            <v-checkbox
              :key="index"
              v-model="selectedActs"
              class="medical-act"
              hide-details
              :value="act"
              :value-comparator="(a, b) => a.id === b.id"
              :label="`${act.code ? act.code + ' - ' : ''}${act.description}`"
            />
          </div>
        </template>
        <template v-else>
          <div class="d-flex flex-column align-center justify-center fill-height fill-width">
            <span class="ml-3 text-h6 text-center">{{ $t('medicalTests.rx_not_found') }}</span>
          </div>
        </template>
      </template>
      <div v-else class="d-flex flex-column align-center justify-center fill-width">
        <div class="d-flex flex-row align-center">
          <v-icon large>mdi-magnify</v-icon>
          <span class="ml-3 text-h6 text-center">{{ $t('medicalTests.rx_search_hint') }}</span>
        </div>
      </div>
    </div>
    <div v-else class="d-flex flex-column align-center justify-center medical-test-list">
      <v-progress-circular indeterminate color="primary" :size="70" :width="3" />
    </div>
  </div>
</template>

<script>
import { useNomenclator } from '@/use/MedicalTests/useNomenclator';
import { watch } from 'vue';

export default {
  setup: function(props, { emit }) {
    const {
      loadAllSubGroups,
      loadActs,
      setItemsPerPage,
      isLoadingActs,
      selectedActs,
      activeActs,
      filter,
      refreshActs,
      searched,
    } = useNomenclator(JSON.parse(window.localStorage.getItem('auth_ehealth')).user.professional_id, false);

    refreshActs.value = false;
    filter.groupId = '79cd58b7-949e-4658-8133-fdccfae27ac4';

    watch(
      () => selectedActs.value,
      currentValue => {
        return emit('input', currentValue);
      }
    );

    return {
      loadAllSubGroups,
      loadActs,
      setItemsPerPage,
      isLoadingActs,
      selectedActs,
      activeActs,
      filter,
      searched,
    };
  },
};
</script>

<style>
.medical-test-list {
  height: 400px;
}

.medical-test {
  flex: 1 1 285px !important;
}

.fill-width {
  width: 100%;
}
</style>
